import { CardElement } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { db } from "../firebase";
import { doc, setDoc, serverTimestamp, collection } from "firebase/firestore";
import * as Path from "../refPath";
import { updateStripeCustomer } from "./purchaseSlice";
import { Purchase } from "./purchaseSlice";
import { AppThunk } from "../app/store";
import { push } from "connected-react-router";
import {
  stripeAttachPaymentMethod,
  stripeCreateCustomerCard,
  stripeMainCardMainUpdateMethod,
  stripeMainCardUpdateMethod,
  stripeRetrievePaymentMethod,
} from "../function/cloudFunctions";
import { logError } from "../lib/logError";
// Set Header
const headers = new Headers();
headers.set("Content-type", "application/json");
headers.set("Access-Control-Allow-Origin", "*");

const createCustomer = async (
  email: string,
  paymentMethodID: string,
  uid: string,
  username: string
) => {
  try {
    const data = { email, paymentMethodID, uid, username };
    const response = await stripeCreateCustomerCard(data);
    return response;
  } catch (error) {
    logError(error);
  }
};

const fetchCardList = async (customerID: string) => {
  const response = await stripeRetrievePaymentMethod({
    customerID: customerID,
  });

  return response;
};

export const registerCard = (
  stripe: Stripe | null,
  elements: any,
  customerID: string,
  mainCard: boolean,
  setActive: Function
): AppThunk => {
  return async (dispatch, getState) => {
    const user = getState().user.user;
    const email = "test@gmail.com"; // storeにメール足す
    const uid = user.uid;
    const username = user.username;
    if (!stripe || !elements) {
      console.error("Does not exist stripe or elements");
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      console.error("Does not exist cardElement");
      return;
    }

    let registerMainCard = mainCard;

    if (customerID === "" && !mainCard) {
      // 初めての登録だから強制でメインカードにする
      registerMainCard = true;
    }

    let lengthCheckData: any;
    if (customerID !== "") {
      const lengthCheck = await fetchCardList(customerID);
      lengthCheckData = lengthCheck;
      if (lengthCheckData.data.data.length === 0) {
        registerMainCard = true;
      }
    }

    if (customerID !== "" && mainCard) {
      const cardDataList = lengthCheckData.data.data;
      cardDataList.forEach((card: any) => {
        if (card.metadata.primary_card === "true") {
          stripeMainCardUpdateMethod({ paymentMethodID: card.id });
        }
      });
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      metadata: {
        primary_card: registerMainCard ? "true" : "false",
      },
    });

    if (error) {
      setActive(false);
      alert("カードの登録ができませんでした");
      return;
    }

    const paymentMethodID = paymentMethod?.id;

    if (customerID === "") {
      await createCustomer(email, paymentMethodID, uid, username).then(
        async (cus: any) => {
          const docRef = doc(collection(db, Path.credits(uid)));
          await setDoc(docRef, {
            stripeCustomerID: cus.data.id,
            createdAt: serverTimestamp(),
          });
          const p: Purchase = { customerID: cus.data.id };
          dispatch(updateStripeCustomer(p));
          setActive(false);
        }
      );
    } else {
      const attachData = {
        paymentMethodID: paymentMethodID,
        customerID: customerID,
      };

      stripeAttachPaymentMethod(attachData)
        .then((res: any) => {
          logError(res);
          if (res.data.statusCode) {
            alert("カードの登録ができませんでした");
            setActive(false);
            return;
          }
          setActive(false);
          alert("カードの登録が完了しました。");
        })
        .catch((err) => {
          setActive(false);
          throw new Error(err);
        });
    }
  };
};

export const mainCardUpdate = (customerID: string, index: number): AppThunk => {
  return async (dispatch): Promise<void> => {
    const initCardList: any = await fetchCardList(customerID);
    const cardList = initCardList.data.data;

    cardList.map((card: any) => {
      if (card.metadata.primary_card === "true") {
        stripeMainCardUpdateMethod({ paymentMethodID: card.id })
          .then((res) => {})
          .catch((err) => {
            logError(err);
          });
      }
    });

    stripeMainCardMainUpdateMethod({ paymentMethodID: cardList[index].id })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        logError(err);
      });
  };
};
