import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

type Props = {
  retailPrice: string;
};

export const ProductPrice = (props: Props) => {
  const { retailPrice } = props;
  const useStyles = makeStyles({
    priceArea: {
      marginTop: 20,
      display: "flex",
      color: "rgb(74,74,74)",
    },
    tax: {
      fontSize: 12,
    },
    shipPrice: {
      fontSize: 14,
      marginLeft: 20,
    },
    price: {
      marginLeft: 20,
      color: "black",
    },
  });
  const classes = useStyles();

  const price = Number(retailPrice).toLocaleString();

  return (
    <div className={classes.priceArea}>
      <div>
        <Typography variant="h5" className={classes.price}>
          {`¥${price} `}
          <span className={classes.tax}>　(税込)　　　</span>
        </Typography>
        <Typography className={classes.shipPrice}>
          送料 0円(無料キャンペーン中)
        </Typography>
        {/* <Typography className={classes.shipPrice}>送料 600円</Typography> */}
      </div>
    </div>
  );
};
