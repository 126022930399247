import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Switch, makeStyles } from "@material-ui/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { registerCard } from "../../features/PaymentOperation";
import visa from "../../assets/icon/VISA.png";
import master from "../../assets/icon/Master.png";
import jcb from "../../assets/icon/JCB.png";
import discover from "../../assets/icon/discover.png";
import diners from "../../assets/icon/Diner.png";
import american from "../../assets/icon/American.png";
import CreditNumberTest from "./CreditNumberTest";
import { History as BrowserHistory } from "history";
import { SpaceBox } from "../UIKit/SpaceBox";
import { creditsCollection } from "../../firebasePaths";
import { getDocs } from "firebase/firestore";
import { Text } from "../atoms/Text/Text";
import { Center } from "../atoms/Layout/Center";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Loading } from "../atoms/Loading/Loading";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

type Props = {
  history: BrowserHistory;
  uid: string;
  salesProject?: unknown;
};

export const StripeCreditCreate = (props: Props) => {
  const { history, uid, salesProject } = props;
  const originalWidth = 65.115;
  const originalHeight = 44.379;

  const newWidth = (originalWidth * 3) / 4;
  const newHeight = (originalHeight * 3) / 4;
  const useStyles = makeStyles({
    cardText: {
      margin: "20px 0",
    },
    cardKindText: {},
    inputArea: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    checked: {
      border: "1px solid gray",
      borderRadius: 20,
      width: "100%",
      height: 50,
      backgroundColor: "lightcyan",
      "& p": {
        fontSize: 14,
        margin: "0 0 0 0",
        marginLeft: 30,
        lineHeight: 3.5,
      },
    },
    unChecked: {
      border: "1px solid gray",
      borderRadius: 20,
      width: "100%",
      height: 50,
      backgroundColor: "white",
      "& p": {
        fontSize: 14,
        margin: "0 0 0 0",
        marginLeft: 30,
        lineHeight: 3.5,
      },
    },
    cardBlockFlex: {
      display: "flex",
    },
    cardBlock: {
      flex: 1,
    },
    cardImage: {
      width: originalWidth,
      height: originalHeight,
      "@media screen and (max-width: 499px)": {
        width: newWidth,
        height: newHeight,
      },
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const element = useElements();
  const { push } = useHistory();
  const [customerID, setCustomerID] = useState("");
  const [mainCard, setMainCard] = useState(false);
  const customerIDRef = creditsCollection(uid);

  const [isActive, setActive] = useState(false);

  useEffect(() => {
    let isMounted: boolean = true;

    getDocs(customerIDRef).then((snapshot) => {
      snapshot.forEach((cusData) => {
        const data = cusData.data();
        if (isMounted) {
          setCustomerID(data.stripeCustomerID);
        }
      });
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const creditDataCreate = async () => {
    setActive(true);
    try {
      await dispatch(
        registerCard(stripe, element, customerID, mainCard, setActive)
      );
      const cardElement = element?.getElement(CardElement);
      if (cardElement) {
        cardElement.clear();
      }
    } catch (error) {}
  };

  const switchCheck = () => {
    setMainCard(!mainCard);
  };

  const switchText = () => {
    if (mainCard) return "ON";
    return "OFF";
  };

  return (
    <div>
      <BrowserBackHeader label="クレジット登録" browserBack={history} />
      {isActive && <Loading text="登録中" />}
      <Text
        text="登録するカード番号を入力してください"
        className={{
          margin: "20px 0",
        }}
      />
      <CardElement
        options={{
          hidePostalCode: true,
          style: {
            base: {
              fontSize: "16px",
              color: "#424700",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
      />
      <SpaceBox height={20} />
      <div className={classes.inputArea}>
        <Text
          text={`メインカードに設定する(${switchText()})`}
          className={{
            TextAlign: "center",
          }}
        />
        <Switch
          onChange={() => {
            switchCheck();
          }}
          color="primary"
          value={mainCard}
        />
      </div>
      <Text
        text="登録可能カード"
        className={{
          margin: "20px 0 5px 0",
        }}
      />
      <div className={classes.cardBlockFlex}>
        <div className={classes.cardBlock}>
          <img src={visa} className={classes.cardImage} alt="visaCard" />
        </div>
        <div className={classes.cardBlock}>
          <img src={master} className={classes.cardImage} alt="masterCard" />
        </div>
        <div className={classes.cardBlock}>
          <img src={jcb} className={classes.cardImage} alt="jcbCard" />
        </div>
        <div className={classes.cardBlock}>
          <img
            src={discover}
            className={classes.cardImage}
            alt="discoverCard"
          />
        </div>
        <div className={classes.cardBlock}>
          <img src={diners} className={classes.cardImage} alt="dinersCard" />
        </div>
        <div className={classes.cardBlock}>
          <img
            src={american}
            className={classes.cardImage}
            alt="americanCard"
          />
        </div>
      </div>
      <SpaceBox height={30} />
      <Center>
        <GrayButton onClick={creditDataCreate} width="100%">
          カード情報を登録する
        </GrayButton>
      </Center>
      {salesProject && (
        <Center
          className={{
            marginTop: 20,
          }}
        >
          <GrayButton
            onClick={() => push("/product/reserve", salesProject)}
            width="100%"
          >
            購入画面に戻る
          </GrayButton>
        </Center>
      )}
      <SpaceBox height={50} />
      {process.env.REACT_APP_ENV === "development" && <CreditNumberTest />}
    </div>
  );
};
