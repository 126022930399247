import { Project } from "../../../Types/projectType";
import { useDispatch } from "react-redux";
import { fetchProduct } from "../../../features/productOperation";
import { ProductListPrice } from "../ProductList/ProductListPrice";
import { makeStyles } from "@material-ui/core";
import { Text } from "../../../components/atoms/Text/Text";
import { ProjectPostStatus } from "../../Post/ProjectPost/ProjectPostStatus";
import { useWindowSize } from "react-use";
import { projectRouterChoice } from "../../../features/helpers/routerHelper";
import { useHistory } from "react-router-dom";

type Props = {
  product: Project;
};

export const Product = (props: Props) => {
  const { width } = useWindowSize();
  const { product } = props;
  const useStyles = makeStyles({
    productCard: {
      display: "flex",
      flexWrap: "wrap",
    },
    product: {
      paddingTop: 20,
      width: "50%",
      cursor: "pointer",
      paddingBottom: 10,
      "&:hover": {
        backgroundColor: "rgb(230,230,230)",
        transition: "all 0.3s",
      },
    },
    img: {
      width: 230,
      height: 287.5,
      margin: "0 auto",
      display: "block",
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: width * (172.5 / 375),
        height: width * (215.625 / 375),
      },
    },
    colorArea: {
      marginLeft: 10,
      display: "flex",
      justifyContent: "center",
    },
    colorBox: {
      width: 15,
      height: 15,
      border: "1px solid rgba(210, 210, 210, 0.9)",
      margin: "0 5px",
    },
    statusArea: {
      position: "relative",
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  return (
    <div
      key={product.projectRef}
      className={classes.product}
      onClick={() => {
        if (
          product.salesStatus.underReservation ||
          product.salesStatus.sale ||
          product.salesStatus.inProduction ||
          product.salesStatus.endOfSale
        ) {
          dispatch(fetchProduct(product.projectRef));
        }

        const url = projectRouterChoice(
          product.salesStatus,
          product.projectRef
        );

        push(url);
      }}
    >
      <div className={classes.statusArea}>
        {/* {product.salesStatus && <ProjectPostStatus status={product.salesStatus} />} */}
        <img
          src={product.image ? product.image : ""}
          alt="productImage"
          className={classes.img}
        />
      </div>
      {product.salesStatus && (
        <ProjectPostStatus status={product.salesStatus} />
      )}
      <Text
        text={product.memer.label}
        isDark
        className={{
          marginTop: 5,
          marginLeft: 10,
          fontSize: 14,
          "@media screen and (max-width: 499px)": {
            fontSize: 12,
          },
        }}
      />
      <Text
        text={product.projectName}
        isDark
        className={{
          marginLeft: 10,
          marginRight: 10,
          marginTop: 5,
          fontSize: 14,
          "@media screen and (max-width: 499px)": {
            fontSize: 12,
          },
        }}
      />
      {/* <Text
        text={`${firebaseTimestampToJapaneseDate(product.createdAt)}`}
        isDark
        className={{
          marginLeft: 10,
          marginRight: 10,
          marginTop: 5,
          fontSize: 14,
          "@media screen and (max-width: 499px)": {
            fontSize: 12,
          },
        }}
      />
      <Text
        text={product.productNumber}
        isDark
        className={{
          marginLeft: 10,
          marginRight: 10,
          marginTop: 5,
          fontSize: 14,
          "@media screen and (max-width: 499px)": {
            fontSize: 12,
          },
        }}
      /> */}
      <ProductListPrice price={product.retailPrice} />
      <div className={classes.colorArea}>
        {product.colors?.map((c, idx) => (
          <div
            key={idx}
            className={classes.colorBox}
            style={{ backgroundColor: c.value }}
          />
        ))}
      </div>
    </div>
  );
};
