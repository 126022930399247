import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import cuid from "cuid";
import { selectPurchase } from "../../../features/purchaseSlice";
import { LatestColorSalesInfo, SalesProject } from "../../../Types/productType";
import { setReserveProductStore } from "../../../features/productSlice";
import { LoginInfo } from "../../../components/feature/LoginInfo/LoginInfo";
import { useState } from "react";
import { selectUser } from "../../../features/userSlice";
import { GrayButton } from "../../../components/atoms/GrayButton/GrayButton";
import { Center } from "../../../components/atoms/Layout/Center";

type Props = {
  project: SalesProject;
  salesDetailData: LatestColorSalesInfo[];
};

export const ProductBuyButton = (props: Props) => {
  const { project, salesDetailData } = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { push, location } = useHistory();
  const useStyles = makeStyles({
    btnArea: {
      margin: "50px 0",
    },
    box: {
      margin: "0 auto",
    },
    btn: {
      display: "block",
      margin: "0 auto",
      width: 150,
      color: "white",
      fontSize: 12,
      backgroundColor: "rgb(177,177,177)",
      borderRadius: 0,
    },
  });
  const classes = useStyles();
  const customerID = useSelector(selectPurchase);
  const user = useSelector(selectUser);
  const reserveSubmit = (choiceProduct: LatestColorSalesInfo[]) => {
    const {
      salesStatus,
      projectRef,
      toBeShipDate,
      projectName,
      retailPrice,
      images,
      category,
    } = project;
    const image = images[0];
    const itemName = category.label;
    const reserveProducts = choiceProduct
      .filter((product) => product.nowQuantity > 0)
      .map(({ color, value, detail }) => ({
        color,
        value,
        detail: detail
          .filter(({ selectedQuantity }) => selectedQuantity > 0)
          .map(({ size, ref, selectedQuantity }) => ({
            size,
            ref,
            selectedQuantity,
          })),
      }));

    const selectedQuantityTotal = reserveProducts.reduce((acc, curr) => {
      return (
        acc +
        curr.detail.reduce((acc2, curr2) => {
          return acc2 + curr2.selectedQuantity;
        }, 0)
      );
    }, 0);

    const setStore = {
      projectRef,
      toBeShipDate,
      salesStatus,
      projectName,
      retailPrice,
      image,
      reserveProducts,
      selectedQuantityTotal,
      itemName,
    };
    const reserveId = cuid();
    dispatch(setReserveProductStore(setStore));
    const projectInCustomerIdData = {
      ...project,
      customerID,
      reserveId,
      prevLocation: location.pathname,
    };
    push("/product/reserve", projectInCustomerIdData);
  };

  const isActive = () => {
    const check = salesDetailData.filter((data) => data.nowQuantity > 0);
    return check.length === 0;
  };

  const reserveSend = () => {
    if (user.isSignedIn) {
      reserveSubmit(salesDetailData);
      return;
    }
    setShow(true);
  };
  return (
    <div className={classes.btnArea}>
      <Center>
        <GrayButton
          width={300}
          disabled={isActive()}
          onClick={() => reserveSend()}
          backgroundColor="rgb(106,106,106)"
          disabledColor="rgb(175, 175, 175)"
          className={{
            height: 50,
            fontSize: 16,
          }}
        >
          予約注文する
        </GrayButton>
      </Center>
      <LoginInfo
        isShow={show}
        setState={setShow}
        targetUrl={location.pathname}
      />
    </div>
  );
};
