import { makeStyles, Typography } from "@material-ui/core";

type Props = {
  price: number;
};
export const ProductListPrice = (props: Props) => {
  const { price } = props;
  const useStyles = makeStyles({
    priceArea: {
      margin: "5px 10px 10px 10px",
    },
    price: {
      color: "rgb(80, 80, 80)",
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
  });
  const classes = useStyles();
  const viewPrice = price.toLocaleString();

  return (
    <div className={classes.priceArea}>
      <Typography
        className={classes.price}
      >{`¥ ${viewPrice}(税込)`}</Typography>
    </div>
  );
};
