import { getFunctions, httpsCallable } from "firebase/functions";
// import { functions } from "../firebase";
import Stripe from "stripe";
import { PaymentIntentMetaDataType } from "../Types/stripeType";
type ResInterface = {
  data: string[];
};

const functions = getFunctions();
functions.region = "asia-northeast1";
/* Slack */
export const slackNotice = httpsCallable(functions, "slackNotice");
/* Project */
export const requestProject = httpsCallable(functions, "requestProject");

/* Product */
export const sendReserveProduct = httpsCallable<unknown, string[]>(
  functions,
  "sendReserveProduct"
);
export const sendBuyProduct = httpsCallable(functions, "sendBuyProduct");

/* ProjectPost */
export const projectPostWanted = httpsCallable(functions, "projectPostWanted");
export const projectPostUnWanted = httpsCallable(
  functions,
  "projectPostUnWanted"
);

/* Social */
export const followerAddApi = httpsCallable(functions, "followerAddApi");
export const initEmailVerified = httpsCallable(functions, "initEmailVerified");
export const creatorHopeEmail = httpsCallable(functions, "creatorHopeEmail");
export const inquiryAdminNotice = httpsCallable(
  functions,
  "inquiryAdminNotice"
);
export const passwordReset = httpsCallable(functions, "passwordReset");
export const unLoginPasswordReset = httpsCallable(
  functions,
  "unLoginPasswordReset"
);
export const sendComment = httpsCallable(functions, "sendComment");
export const deletePost = httpsCallable<unknown, string[]>(
  functions,
  "deletePost"
);
export const deleteComment = httpsCallable(functions, "deleteComment");
export const sendReplayComment = httpsCallable(functions, "sendReplayComment");

/* Stripe */
export const stripeRetrievePaymentIntent = httpsCallable<
  unknown,
  PaymentIntentMetaDataType
>(functions, "stripeRetrievePaymentIntent");
export const stripeIntentPaymentMethod = httpsCallable<
  unknown,
  Stripe.Response<Stripe.PaymentIntent> & { statusCode?: number }
>(functions, "stripeIntentPaymentMethod");
export const stripeCreateCustomerCard = httpsCallable(
  functions,
  "stripeCreateCustomerCard"
);
export const stripeRetrievePaymentMethod = httpsCallable<
  unknown,
  Stripe.Response<Stripe.ApiList<Stripe.PaymentMethod>>
>(functions, "stripeRetrievePaymentMethod");
export const stripeMainCardUpdateMethod = httpsCallable(
  functions,
  "stripeMainCardUpdateMethod"
);
export const stripeAttachPaymentMethod = httpsCallable(
  functions,
  "stripeAttachPaymentMethod"
);
export const stripeMainCardMainUpdateMethod = httpsCallable(
  functions,
  "stripeMainCardMainUpdateMethod"
);
export const stripeDeletePaymentMethod = httpsCallable(
  functions,
  "stripeDeletePaymentMethod"
);

/* Sagawa */
export const countUpOrderNumber = httpsCallable<unknown, string>(
  functions,
  "countUpOrderNumber"
);

/* Admin */
export const adminTrue = httpsCallable(functions, "adminTrue");
