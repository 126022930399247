import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

type Props = {
  height: number | string;
  label?: string;
  align?: string;
  color?: string;
  fontSize?: string;
  isDark?: boolean;
};

export const GrayBack = React.memo((props: Props) => {
  const { height, label, align, color, fontSize, isDark = false } = props;
  const useStyles = makeStyles({
    grayArea: {
      backgroundColor: isDark ? "rgb(200,200,200)" : "rgb(245,245,245)",
    },
    text: {
      height: 29,
      margin: "0 0 0 15px",
      color: "rgb(85,85,85)",
      "text-align": align,
    },
    gray: {
      height: height,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.grayArea}>
      <div className={classes.gray}></div>

      {label && (
        <Typography
          className={classes.text}
          style={{
            color: color,
            fontSize: fontSize,
          }}
        >
          {label}
        </Typography>
      )}
    </div>
  );
});
