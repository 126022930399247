import { useEffect, useState } from "react";
import { GrayBack } from "../MyPage/GrayBack";
import { CreditRegistered } from "./CreditRegistered";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { creditsCollection } from "../../firebasePaths";
import { getDocs } from "firebase/firestore";
import { getStripePublicKey } from "../../function/common";
import { Center } from "../atoms/Layout/Center";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { SpaceBox } from "../UIKit/SpaceBox";

const stripePromise = loadStripe(getStripePublicKey());

export const CreditList = (props: RouteComponentProps<{ uid: string }>) => {
  const { history, match } = props;
  const uid = match.params.uid;
  const customerIDRef = creditsCollection(uid);
  const { push } = useHistory();
  const [customerID, setCustomerID] = useState("");

  useEffect(() => {
    let isMounted: boolean = true;

    getDocs(customerIDRef).then((snapshot) => {
      snapshot.forEach((cusData) => {
        const data = cusData.data();
        if (isMounted) {
          setCustomerID(data.stripeCustomerID);
        }
      });
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <div>
        <BrowserBackHeader
          label="クレジットカードの情報"
          browserBack={history}
        />
        <GrayBack height={15} label="クレジットカード" fontSize="12px" />
        <CreditRegistered customerID={customerID} />
        <SpaceBox height={50} />
        <Center>
          <GrayButton width={300} onClick={() => push(`/${uid}/payment`)}>
            ＋ 新しい支払い方法を登録
          </GrayButton>
        </Center>
        <SpaceBox height={50} />
      </div>
    </Elements>
  );
};
